export const TOKENLOGIN =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzM2NjQ2ODc0LCJpYXQiOjE3MzQwNTQ4NzQsImp0aSI6IjBmNWUzYTc3OGVmNjQ3MjI5NTNiZDYxMTZjMmRjOTFiIiwidXNlcl9pZCI6MX0.eFN2u1OLXzt0WL1hqul2stlPxhxLAYaG5zXSiMLJs9A";
export const ENV = "dev.";
export const URLAPIS = `https://${ENV}apis.sinay.com.mx/`;

let BASE_API,
  BASE_API_SINSLSH,
  TOKEN,
  SA,
  URLPRODUCTION,
  DOMINIOEMPRESARIAL,
  URLAPIIMG;

async function getData() {
  await fetch(`${URLAPIS}configuraciongeneral/get_id/1/`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${TOKENLOGIN}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }
      return response.json();
    })
    .then((data) => {
      SA = data.SCG_SA_principal;
      URLPRODUCTION = data.SCG_url_produccion;
      DOMINIOEMPRESARIAL = data.SCG_Dominio;
      URLAPIIMG = data.SCG_URL;
      if (data.SCG_Puerto == null) {
        BASE_API = `${data.SCG_URL}/`;
        BASE_API_SINSLSH = `https://${ENV}apis.sinay.com.mx`;
      } else BASE_API = `${data.SCG_URL}:${data.SCG_Puerto}/`;
      TOKEN = "token";
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

getData();

export {
  BASE_API,
  TOKEN,
  BASE_API_SINSLSH,
  SA,
  URLPRODUCTION,
  DOMINIOEMPRESARIAL,
  URLAPIIMG,
};
